// External
import { type DrawerScreenProps } from '@react-navigation/drawer'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, RefreshControl, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { EmailVerificationAlert } from '@/auth/components'
import { Text, TopBar } from '@/common/components'
import { Facility, ModuleItem, ModuleItemSkeleton } from '@/home/components'
// Constants
import { colors, toast } from '@/common/constants'
// Layouts
import { AnimatedDrawerScreen } from '@/common/layouts'
// Models
import { InductionStatus, Modules } from '@/common/models'
import type { HomeDrawerParamList } from '@/home/models'
// Hooks
import useForceResetPassword from '@/auth/hooks/useForceResetPassword'
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
import useCheckUserType from '@/home/hooks/useCheckUserType'
import useFacilityUpdates from '@/home/hooks/useFacilityUpdates'
import useUserUpdates from '@/home/hooks/useUserUpdates'
import useUnreadNotificationCount from '@/notifications/hooks/useUnreadNotificationCount'
import useMissingCustomFields from '@/profile/hooks/useMissingCustomFields'
// Services
import { getUser } from '@/common/services'
import { getGeopositionConfig } from '@/geoposition/services'
import {
  getInductionStatus,
  getInductionTest
} from '@/safetyInduction/services'
import { getVirtualMap } from '@/virtualMap/services'
import { getRequestsConfiguration } from '@/visit/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useGeopositionConfigStore from '@/geoposition/stores/useGeopositionConfigStore'
import useNotificationsStore from '@/notifications/stores/useNotificationsStore'
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
// Use cases
import { getUserModules } from '@/home/useCases'
import { getSuspensionStatus } from '@/suspension/useCases'
// Utils
import { callSOS, handleError } from '@/common/utils'

interface Props extends DrawerScreenProps<HomeDrawerParamList, 'Home'> {}

const Home = ({ navigation }: Props) => {
  const {
    user,
    setUser,
    currentUserType,
    currentFacility,
    currentModules,
    setCurrentModules
  } = useAppStore((state) => ({
    user: state.user,
    setUser: state.setUser,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility,
    currentModules: state.currentModules,
    setCurrentModules: state.setCurrentModules
  }))
  const unreadNotifications = useNotificationsStore(
    (state) => state.unreadNotifications
  )
  const { geopositionConfig, setGeopositionConfig } = useGeopositionConfigStore(
    (state) => ({
      geopositionConfig: state.geopositionConfig,
      setGeopositionConfig: state.setGeopositionConfig
    })
  )
  const { setPTWConfig } = usePTWConfigStore((state) => ({
    setPTWConfig: state.setPTWConfig
  }))

  const { top, left, right, bottom } = useSafeAreaInsets()
  const { t } = useTranslation()

  useUnreadNotificationCount({
    userId: user?._id,
    facilityId: currentFacility?._id,
    userTypeId: currentUserType?._id
  })
  useMissingCustomFields()
  useUserUpdates()
  useFacilityUpdates()
  useCheckUserType()

  const { data: userData, refetch: userRefetch } = useQuery({
    queryKey: ['user', user?._id],
    queryFn: getUser
  })

  useEffect(() => {
    if (userData !== undefined) {
      setUser(userData)
    }
  }, [userData])

  useForceResetPassword(userData)

  const {
    data: modulesData,
    isLoading: modulesLoading,
    refetch: modulesRefetch
  } = useQuery({
    queryKey: [
      'modules',
      user?._id,
      currentUserType?._id,
      currentFacility?._id
    ],
    queryFn: getUserModules
  })

  useEffect(() => {
    if (modulesData !== undefined) {
      setCurrentModules(modulesData.modules)
      if (modulesData.geopositionConfig !== null) {
        setGeopositionConfig(modulesData.geopositionConfig)
      }

      if (modulesData.PTWConfig !== null) {
        setPTWConfig(modulesData.PTWConfig)
      }
    }
  }, [modulesData])

  const {
    data: inductionStatusData,
    isLoading: inductionStatusLoading,
    isRefetching: inductionStatusRefetching,
    refetch: inductionStatusRefetch
  } = useQuery({
    queryKey: [
      'inductionStatus',
      user?._id,
      currentUserType?._id,
      currentFacility?._id,
      currentModules
    ],
    ...(currentModules.includes(Modules.SAFETY_INDUCTION) && {
      queryFn: getInductionStatus
    }),
    staleTime: 60000,
    refetchInterval: 60000
  })

  const isInductionLoading = inductionStatusLoading || inductionStatusRefetching

  const {
    data: suspensionStatusData,
    isLoading: suspensionStatusLoading,
    isRefetching: suspensionStatusRefetching,
    refetch: suspensionStatusRefetch
  } = useQuery({
    queryKey: ['suspensionStatus', user?._id, currentFacility?._id],
    ...(currentModules.includes(Modules.SUSPENSION) && {
      queryFn: getSuspensionStatus
    }),
    staleTime: 60000,
    refetchInterval: 60000
  })

  const isSuspensionLoading =
    suspensionStatusLoading || suspensionStatusRefetching

  const {
    data: requestsConfigurationData,
    refetch: requestsConfigurationRefetch
  } = useQuery({
    queryKey: ['requestsConfiguration', currentFacility?._id, currentModules],
    ...((currentModules?.includes(Modules.VISIT) ?? false) && {
      queryFn: getRequestsConfiguration
    })
  })

  useRefetchOnFocus([
    userRefetch,
    modulesRefetch,
    inductionStatusRefetch,
    suspensionStatusRefetch,
    requestsConfigurationRefetch
  ])

  const onRefreshControl = async () => {
    await Promise.all([
      userRefetch(),
      modulesRefetch(),
      inductionStatusRefetch(),
      suspensionStatusRefetch(),
      requestsConfigurationRefetch()
    ])
  }

  const requiresInductionForRequests =
    requestsConfigurationData?.safetyInductionRequired === true &&
    inductionStatusData?.status !== undefined &&
    [InductionStatus.EXPIRED, InductionStatus.NOT_INDUCTED].includes(
      inductionStatusData.status
    )

  if (currentFacility === undefined) {
    return null
  }

  return (
    <AnimatedDrawerScreen style={{ backgroundColor: colors.white }}>
      <TopBar
        style={{
          paddingTop: top,
          paddingLeft: left + 25,
          paddingRight: right + 25
        }}
        leftIcon={{
          name: 'menu',
          onPress: navigation.openDrawer
        }}
        rightIcons={[
          {
            name: 'sos',
            onPress: () => {
              void callSOS(currentFacility)
            },
            color: 'error'
          },
          {
            name: 'qr',
            onPress: () => {
              navigation.navigate('QRCode')
            }
          },
          {
            name: 'notifications',
            onPress: () => {
              navigation.navigate('Notifications')
            },
            badgeText:
              unreadNotifications > 0
                ? unreadNotifications.toString()
                : undefined
          }
        ]}
      />

      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        refreshControl={
          <RefreshControl
            refreshing={modulesLoading}
            onRefresh={onRefreshControl}
          />
        }
      >
        <EmailVerificationAlert />

        <Facility
          style={{
            marginTop: 9,
            paddingLeft: left + 25,
            paddingRight: right + 25
          }}
        />

        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingBottom: bottom + 25,
            paddingLeft: left + 25,
            paddingRight: right + 25
          }}
          bounces={false}
        >
          <Text variant="baseBold" style={{ marginBottom: 20 }}>
            {t('module')}
          </Text>

          {modulesLoading ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <ModuleItemSkeleton key={index} />
              ))}
            </>
          ) : (
            <View style={{ gap: 15 }}>
              {currentModules.includes(Modules.SAFETY_INDUCTION) && (
                <ModuleItem
                  iconBackgroundColor="#D8E2E8"
                  iconName="safety-induction"
                  loading={isInductionLoading}
                  icon={
                    inductionStatusData?.status ===
                      InductionStatus.NOT_INDUCTED ||
                    inductionStatusData?.status === InductionStatus.EXPIRED
                      ? {
                          name: 'alert-fill',
                          color: colors.rajah
                        }
                      : {
                          name: 'check-fill',
                          color: colors.success
                        }
                  }
                  titleTranslationKey="induction"
                  textTranslationKey="videoPlusText"
                  onPress={async () => {
                    try {
                      if (
                        inductionStatusData?.status ===
                        InductionStatus.COMPLETED
                      ) {
                        navigation.navigate('InductionStack', {
                          screen: 'SuccessfulInduction',
                          params: {
                            alreadyCompleted: true,
                            uuid: inductionStatusData.uuid,
                            expirationDate: inductionStatusData.expirationDate
                          }
                        })
                        return
                      }

                      const inductionTest = await getInductionTest()

                      if (
                        inductionTest.inductionVideo !== null ||
                        inductionTest.fallbackVideo !== null
                      ) {
                        navigation.navigate('InductionStack', {
                          screen: 'InductionVideo',
                          params: {
                            inductionTest
                          }
                        })
                        return
                      }

                      navigation.navigate('InductionStack', {
                        screen: 'InductionTest',
                        params: {
                          questions: inductionTest.questions
                        }
                      })
                    } catch (error) {
                      handleError(error)
                    }
                  }}
                />
              )}

              {currentModules.includes(Modules.GEOPOSITION) &&
                Platform.OS !== 'web' && (
                  <ModuleItem
                    iconBackgroundColor="#FAE6DB"
                    iconName="geoposition"
                    titleTranslationKey="geoposition"
                    textTranslationKey="yourCurrentPosition"
                    onPress={async () => {
                      try {
                        if (geopositionConfig === undefined) {
                          const res = await getGeopositionConfig()
                          setGeopositionConfig(res)
                        }

                        navigation.navigate('Geoposition')
                      } catch (error) {
                        handleError(error)
                      }
                    }}
                  />
                )}

              {currentModules.includes(Modules.VIRTUAL_MAP) && (
                <ModuleItem
                  iconBackgroundColor="#DDEDEB"
                  iconName="3d-map"
                  titleTranslationKey="3dMap"
                  textTranslationKey="3dMapOfTheFacility"
                  onPress={async () => {
                    try {
                      const { url } = await getVirtualMap()

                      if (Platform.OS === 'web') {
                        window.open(url, '_blank')
                        return
                      }

                      navigation.navigate('VirtualMap', {
                        link: url
                      })
                    } catch (error) {
                      handleError(error)
                    }
                  }}
                />
              )}

              {currentModules.includes(Modules.PERMIT_TO_WORK) &&
                currentUserType?.slug === 'contractor' && (
                  <ModuleItem
                    iconName="permit-to-work"
                    iconBackgroundColor="#D8EEF3"
                    titleTranslationKey="permitToWork"
                    textTranslationKey="viewAndCreatePermitRequests"
                    onPress={() => {
                      navigation.navigate('PermitToWorkStack', {
                        screen: 'PermitToWork'
                      })
                    }}
                  />
                )}

              {currentModules.includes(Modules.SUSPENSION) && (
                <ModuleItem
                  loading={isSuspensionLoading}
                  iconName="suspension"
                  iconBackgroundColor="#E8E4ED"
                  {...(suspensionStatusData === true && {
                    icon: {
                      name: 'alert-fill',
                      color: colors.rajah
                    }
                  })}
                  titleTranslationKey="suspensions"
                  textTranslationKey="viewYourSuspensions"
                  onPress={() => {
                    navigation.navigate('SuspensionStack', {
                      screen: 'Suspensions'
                    })
                  }}
                />
              )}

              {currentModules.includes(Modules.VISIT) &&
                requestsConfigurationData?.visitRequestsEnabled === true && (
                  <ModuleItem
                    {...(requiresInductionForRequests && {
                      style: {
                        opacity: 0.33
                      }
                    })}
                    iconBackgroundColor="#D8E2E8"
                    iconName="visit"
                    titleTranslationKey="visits"
                    textTranslationKey="viewAndScheduleVisits"
                    onPress={() => {
                      if (requiresInductionForRequests) {
                        toast.showError({
                          data: {
                            messageTranslationKey: 'safetyInductionRequired'
                          }
                        })
                        return
                      }

                      navigation.navigate('VisitStack', {
                        screen: 'Visits'
                      })
                    }}
                  />
                )}
            </View>
          )}
        </ScrollView>
      </ScrollView>
    </AnimatedDrawerScreen>
  )
}

export default Home
