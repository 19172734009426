// External
import { yupResolver } from '@hookform/resolvers/yup'
import type { StackScreenProps } from '@react-navigation/stack'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { array, object, string, type InferType } from 'yup'
// Components
import { ProgressBar, StepNavigationButtons, Text } from '@/common/components'
import { InductionTestQuestion } from '@/safetyInduction/components'
// Constants
import { toast } from '@/common/constants'
// Models
import type { InductionStackParamList } from '@/safetyInduction/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { submitInductionTest } from '@/safetyInduction/useCases'
// Utils
import { handleError } from '@/common/utils'

const FormSchema = object({
  answers: array(
    object({
      questionId: string().required(),
      answerId: string().required()
    })
  ).required()
})

type FormValues = InferType<typeof FormSchema>

type Props = StackScreenProps<InductionStackParamList, 'InductionTest'>

const InductionTest = ({ navigation, route }: Props) => {
  const { t } = useTranslation()
  const currentUserType = useAppStore((state) => state.currentUserType)
  const { top, left, right, bottom } = useSafeAreaInsets()

  const methods = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      answers: route.params.questions.map((question) => ({
        questionId: question._id,
        answerId: ''
      }))
    }
  })

  const { control, handleSubmit } = methods

  const onSubmit = async (values: FormValues) => {
    try {
      const { testCompleted, expirationDate } = await submitInductionTest(
        values.answers.map((a) => ({
          question: a.questionId,
          answer: a.answerId
        }))
      )

      if (testCompleted) {
        navigation.navigate('SuccessfulInduction', {
          expirationDate,
          alreadyCompleted: false
        })
        return
      }

      toast.showError({
        data: {
          titleTranslationKey: 'inductionTestFailed',
          messageTranslationKey: 'pleaseReviewYourAnswers'
        }
      })
    } catch (error) {
      handleError(error)
    }
  }

  const { fields } = useFieldArray({
    control,
    name: 'answers'
  })

  if (currentUserType === undefined) {
    return null
  }

  return (
    <View style={{ flex: 1, marginTop: top }}>
      <View
        style={{
          marginTop: 11,
          marginLeft: left + 25,
          marginRight: right + 25,
          marginBottom: 22
        }}
      >
        <ProgressBar value={0} />

        <Text variant="h2Bold" style={styles.title}>
          {t('safetyInductionVideo', {
            userType: t(`userTypes.${currentUserType.slug}`)
          })}
        </Text>

        <Text>{t('pleaseAnswerCorrectly')}</Text>
      </View>

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: 22,
          paddingLeft: left + 25,
          paddingRight: right + 25
        }}
        bounces={false}
      >
        <View style={styles.testQuestions}>
          <FormProvider {...methods}>
            {fields.map(({ answerId, questionId }, index) => (
              <InductionTestQuestion
                key={`${questionId}${answerId}`}
                index={index}
                question={
                  route.params.questions.find((q) => q._id === questionId)!
                }
              />
            ))}
          </FormProvider>
        </View>
      </ScrollView>

      <StepNavigationButtons
        style={{
          paddingTop: 25,
          marginLeft: left + 25,
          marginRight: right + 25,
          marginBottom: bottom + 25
        }}
        nextOnPress={handleSubmit(onSubmit)}
      />
    </View>
  )
}

export default InductionTest

const styles = StyleSheet.create({
  title: {
    marginTop: 34,
    marginBottom: 9
  },
  testQuestions: {
    gap: 44,
    marginBottom: 25
  }
})
